import { apiPrefix } from '~/api';
/**
 * Call "Get Notification List" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/notification/
 */
export function getNotificationList($http, topOnly) {
    const query = topOnly ? '?scope=top' : '';
    return $http.$get(`${apiPrefix}/notification${query}`);
}
/**
 * Call "Get Notification Info" API.
 * @see https://github.com/ddradar/ddradar/tree/master/api/notification__id/
 */
export function getNotificationInfo($http, id) {
    return $http.$get(`${apiPrefix}/notification/${id}`);
}
/**
 * Call "Post Notification" API. (Admin only)
 * @see https://github.com/ddradar/ddradar/tree/master/api/notification--post/
 */
export function postNotification($http, body) {
    return $http.$post(`${apiPrefix}/notification`, {
        ...body,
        sender: 'SYSTEM',
    });
}
