import { computed, defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'CardComponent',
    props: {
        title: { type: String, required: true },
        type: { type: String, required: true },
        collapsible: { type: Boolean, default: false },
        open: { type: Boolean, default: false },
    },
    setup(props) {
        // Computed
        const icon = computed(() => (props.open ? 'menu-down' : 'menu-up'));
        return { icon };
    },
});
