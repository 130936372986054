export function success($buefy, message) {
    callNotification($buefy, 'is-success', message);
}
export function warning($buefy, errorOrMessage) {
    callNotification($buefy, 'is-warning', convertToMessage(errorOrMessage));
}
export function danger($buefy, error) {
    callNotification($buefy, 'is-danger', convertToMessage(error));
}
function callNotification($buefy, type, message) {
    $buefy.notification.open({ message, type, position: 'is-top', hasIcon: true });
}
function convertToMessage(error) {
    return error instanceof Error ? error.message : `${error}`;
}
