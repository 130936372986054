var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Song } from '@ddradar/core';
import { Component, Vue } from 'nuxt-property-decorator';
import { getNotificationList } from '~/api/notification';
import { shortenSeriesName } from '~/api/song';
import SearchBox from '~/components/pages/SearchBox.vue';
import TopMessage from '~/components/pages/TopMessage.vue';
import Card from '~/components/shared/Card.vue';
import * as popup from '~/utils/popup';
let IndexPage = class IndexPage extends Vue {
    constructor() {
        super(...arguments);
        this.messages = [];
    }
    get head() {
        return { title: 'DDRadar - DDR Score Tracker', titleTemplate: '' };
    }
    get menuList() {
        const seriesList = [...Song.seriesSet];
        return [
            {
                label: this.$t('search.name'),
                items: [...Song.nameIndexMap.entries()].map(([i, s]) => ({
                    name: s,
                    to: `/name/${i}`,
                })),
            },
            {
                label: this.$t('search.single'),
                items: [...Array(19).keys()].map(i => ({
                    name: `${i + 1}`,
                    to: `/single/${i + 1}`,
                })),
            },
            {
                label: this.$t('search.double'),
                items: [...Array(19).keys()].map(i => ({
                    name: `${i + 1}`,
                    to: `/double/${i + 1}`,
                })),
            },
            {
                label: this.$t('search.series'),
                items: seriesList
                    .map((name, i) => ({
                    name: shortenSeriesName(name),
                    to: `/series/${i}`,
                }))
                    .reverse(),
            },
            {
                label: this.$t('search.course'),
                items: [16, 17]
                    .map(i => [
                    {
                        name: this.$t('nonstop', {
                            series: shortenSeriesName(seriesList[i]),
                        }),
                        to: `/nonstop/${i}`,
                    },
                    {
                        name: this.$t('grade', {
                            series: shortenSeriesName(seriesList[i]),
                        }),
                        to: `/grade/${i}`,
                    },
                ])
                    .flat(),
            },
        ];
    }
    async fetch() {
        try {
            this.messages = await getNotificationList(this.$http, true);
        }
        catch (error) {
            const message = error instanceof Error ? error.message : `${error}`;
            if (message !== '404') {
                popup.danger(this.$buefy, message);
            }
        }
    }
};
IndexPage = __decorate([
    Component({
        components: { Card, SearchBox, TopMessage },
        fetchOnServer: false,
    })
], IndexPage);
export default IndexPage;
