import { computed, defineComponent } from '@nuxtjs/composition-api';
import { marked } from 'marked';
import { unixTimeToString } from '~/utils/date';
export default defineComponent({
    props: {
        type: { type: String, required: true },
        icon: { type: String, default: null },
        title: { type: String, required: true },
        body: { type: String, required: true },
        time: { type: Number, required: true },
    },
    setup(props) {
        // Computed
        const date = computed(() => unixTimeToString(props.time));
        const markedContent = computed(() => marked.parse(props.body));
        return { date, markedContent };
    },
});
